export default {
  expenseTypeApiAddBeginBegin(state) {
    state.loading = true;
  },
  expenseTypeApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiReadBegin(state) {
    state.loading = true;
  },

  expenseTypeApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiUpdateBegin(state) {
    state.loading = true;
  },

  expenseTypeApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiDeleteBegin(state) {
    state.loading = true;
  },

  expenseTypeApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiSingleDataBegin(state) {
    state.loading = true;
  },

  expenseTypeApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  expenseTypeApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiUploadBegin(state) {
    state.fileLoading = true;
  },

  expenseTypeApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  expenseTypeApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  expenseTypeApiSearchBegin(state) {
    state.loading = true;
  },

  expenseTypeApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  expenseTypeApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  expenseTypeApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.data.recordsTotal++;
    state.data.data.push(data);
  },
  expenseTypeApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },
  emptyExpenceTypeData(state) {
    state.data = [];
  },
  expenseTypeApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == data.id);
    state.data.data[foundIndex] = data;
  },
  expenseTypeApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.data.findIndex((x) => x.id == id);
    state.data.data.splice(foundIndex, 1);
  },
};
