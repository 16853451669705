export default {
  saleApiAddBeginBegin(state) {
    state.loading = true;
  },
  saleApiAddBeginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiReadBegin(state) {
    state.loading = true;
  },

  saleApiReadErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiUpdateBegin(state) {
    state.loading = true;
  },

  saleApiUpdateErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiDeleteBegin(state) {
    state.loading = true;
  },

  saleApiDeleteErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiSingleDataBegin(state) {
    state.loading = true;
  },

  saleApiSingleDataSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  saleApiSingleDataErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiUploadBegin(state) {
    state.fileLoading = true;
  },

  saleApiUploadSuccess(state, data) {
    state.fileLoading = false;
    state.url = data;
    state.error = false;
  },

  saleApiUploadErr(state, err) {
    state.fileLoading = false;
    state.error = err;
  },

  saleApiSearchBegin(state) {
    state.loading = true;
  },

  saleApiSearchSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  saleApiSearchErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  saleApiAddBeginSuccess(state, data) {
    state.loading = false;
    state.saleId = data.id;
    state.saleNumber = data.number;
    state.data = data;
    state.saleConfirmed = data.saleStatus == 2;
  },
  saleApiGenerateSuccess(state, data) {
    state.loading = false;
    state.saleNumber = data.message;
  },
  saleApiReadSuccess(state, data) {
    state.loading = false;
    state.data = data;
    state.saleConfirmed = data.saleStatus == 2;
    state.saleId = data.id;
  },
  saleApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    state.saleConfirmed = data.saleStatus == 2;
  },
  saleListApiUpdateBeginSuccess(state, data) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == data.data.saleId);
    if (foundIndex > 0) {
      let editSale = state.data[foundIndex];
      editSale.saleStatus = 2,
        editSale.customerPoDate = data.data.customerPoDate;
      editSale.customerPoNumber = data.data.customerPoNumber;
    }
  },

  saleApiDeleteSuccess(state, id) {
    state.loading = false;
    var foundIndex = state.data.findIndex((x) => x.id == id);
    state.data.splice(foundIndex, 1);
  },
  saleUpdateValiditySuccess(state, data) {
    state.saleIsValid = data;
  },

  saleUpdateValidityError(state, err) {
    state.saleIsValid = false;
    state.error = err;
  },
  emptySaleData(state) {
    state.loading = false;
    state.data = [];
    state.saleId = null;
  },
};
