import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Bank Operation added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  totalIncome: 0,
  totalOutcome: 0,
  allTotalIncome: 0,
  allTotalOutcome: 0,
  error: null,
});

const actions = {
  async bankOperationSubmitData({ commit, dispatch }, data) {
    try {
      await commit("bankOperationApiAddBeginBegin");
      const response = await DataService.post(
        "api/BankOperation",
        data.formState
      );
      await commit("bankOperationApiAddBeginSuccess", response.data);
      dispatch("bankOperationGetTotal", data.pagination);
      addNotificationSuccess();
    } catch (err) {
      await commit("bankOperationApiAddBeginErr", err);
      notificationError(err);
    }
  },

  async bankOperationGetPagedData({ commit }, pagination) {
    try {
      await commit("bankOperationApiReadBegin");
      const query = await DataService.get(
        `api/BankOperation?PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.OrderBy}&OrderByDirection=${pagination.sorter.order}&dateFrom=${pagination.filters.dateFrom}&dateTo=${pagination.filters.dateTo}&isPartnerOrOccasional=${pagination.filters.isPartnerOrOccasional}&occasionalName=${pagination.filters.occasionalName}&incomeOutcome=${pagination.filters.incomeOutcome}&bankAccountId=${pagination.filters.bankAccountId}&operationSupport=${pagination.filters.operationSupport}&partenaireId=${pagination.filters.partenaireId}&occasionalLocalExternal=${pagination.filters.occasionalLocalExternal}`
      );
      await commit("bankOperationApiReadSuccess", query);
    } catch (err) {
      await commit("bankOperationApiReadErr", err);
    }
  },
  async bankOperationGetAllData({ commit }) {
    try {
      await commit("bankOperationApiReadBegin");

      const query = await DataService.get(
        `api/BankOperation?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("bankOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("bankOperationApiReadErr", err);
    }
  },
  async bankOperationGetTotal({ commit }, pagination) {
    try {
      await commit("bankOperationApiReadBegin");

      const query = await DataService.get(
        `api/BankOperation/GetTotal?PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.OrderBy}&OrderByDirection=${pagination.sorter.order}&dateFrom=${pagination.filters.dateFrom}&dateTo=${pagination.filters.dateTo}&isPartnerOrOccasional=${pagination.filters.isPartnerOrOccasional}&occasionalName=${pagination.filters.occasionalName}&incomeOutcome=${pagination.filters.incomeOutcome}&bankAccountId=${pagination.filters.bankAccountId}&operationSupport=${pagination.filters.operationSupport}&partenaireId=${pagination.filters.partenaireId}&occasionalLocalExternal=${pagination.filters.occasionalLocalExternal}`
      );
      const queryAlltotal = await DataService.get(
        `api/BankOperation/GetTotalWOPagination?PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderBy=${pagination.OrderBy}&OrderByDirection=${pagination.sorter.order}&dateFrom=${pagination.filters.dateFrom}&dateTo=${pagination.filters.dateTo}&isPartnerOrOccasional=${pagination.filters.isPartnerOrOccasional}&occasionalName=${pagination.filters.occasionalName}&incomeOutcome=${pagination.filters.incomeOutcome}&bankAccountId=${pagination.filters.bankAccountId}&operationSupport=${pagination.filters.operationSupport}&partenaireId=${pagination.filters.partenaireId}&occasionalLocalExternal=${pagination.filters.occasionalLocalExternal}`
      );
      await commit("bankOperationTotlaApiReadSuccess", { total: query.data, allTotal: queryAlltotal.data });
    } catch (err) {
      await commit("bankOperationApiReadErr", err);
    }
  },

  async bankOperationInfo({ commit }) {
    try {
      await commit("bankOperationApiReadBegin");
      const query = await DataService.get(
        `api/BankOperation?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("bankOperationApiReadSuccess", query.data);
    } catch (err) {
      await commit("bankOperationApiReadErr", err);
    }
  },

  async bankOperationUpdateData({ commit, dispatch }, data) {
    try {
      await commit("bankOperationApiUpdateBegin");
      const response = await DataService.put(
        `api/BankOperation/${data.formState.id}`,
        data.formState
      );
      await commit("bankOperationApiUpdateBeginSuccess", response.data);
      dispatch("bankOperationGetTotal",  data.pagination );
      updateNotificationSuccess();
    } catch (err) {
      await commit("bankOperationApiUpdateErr", err);

      notificationError(err);
    }
  },

  async bankOperationApiDataSearch({ commit }, searchItem) {
    try {
      await commit("bankOperationApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("bankOperationApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("bankOperationApiReadSuccess", query.data);
        } catch (err) {
          await commit("bankOperationApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("bankOperationApiReadErr", err);
    }
  },

  async bankOperationApiDataDelete({ commit, dispatch }, request) {
    try {
      await commit("bankOperationApiDeleteBegin");
      await DataService.delete(`/api/BankOperation/${request.id}`);
      await commit("bankOperationApiDeleteSuccess", request.id);
      dispatch("bankOperationGetTotal", request.pagination);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("bankOperationApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyBankOperation({ commit }) {
    await commit("emptyBankOperation");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
