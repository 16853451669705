import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Product Added",
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: "Product deleted",
  });
};
const restoreNotificationSuccess = () => {
  notification.success({
    message: "Product restored",
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: "Product updated",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async productDealSubmitData({ commit }, data) {
    try {
      await commit("productDealApiAddBeginBegin");
      const response = await DataService.post("/api/product", data.productForm);
      await commit("productDealApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("productDealApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productPublish({ commit }, data) {
    var command = {
      productId: data
    }
    try {
      await commit("productDealApiUpdateBegin");
      const response = await DataService.post("/api/product/Publish", command);
      await commit("productApiStockUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("productDealApiAddBeginErr", err);

      notificationError(err);
    }
  },
  async productDealUpdateData({ commit }, data) {
    try {
      await commit("productDealApiUpdateBegin");
      const response = await DataService.put(
        "/api/product/" + data.productForm.id,
        data.productForm
      );
      await commit("productDealApiUpdateBeginSuccess", response.data);
      updateNotificationSuccess();
    } catch (err) {
      await commit("productDealApiAddBeginErr", err);
      console.log("err", err);
      notificationError(err);
    }
  },

  async updateProductFiles({ commit }, data) {
    await commit("updateProductFiles", data);
  },
  async productGetData({ commit }) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get("/api/products");
      await commit("productDealApiReadSuccess", query.data);
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },
  async dealProductsGetData({ commit }, data) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get(
        `/api/Product?DealId=${data}&addFiles=true&PageSize=10000`
      );
      await commit("productDealApiReadSuccess", query.data);
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },

  async allProductsGetData({ commit }, { pagination }) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get(
        `api/Product?DealId=${pagination.productForm.dealId}&PartnerId=${pagination.productForm.partnerId}&ProductCountry=${pagination.productForm.countryId}&ProductSize=${pagination.productForm.sizeId}&ProductWeight=${pagination.productForm.weightId}&ProductCategory=${pagination.productForm.categoryId}&ProductRange=${pagination.productForm.rangeId}&ProductThread=${pagination.productForm.threadId}&ProductHsCode=${pagination.productForm.hsCodeId}&ProductGrade=${pagination.productForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("productDealApiReadSuccess", query);
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },
  async allProductsGetDataWithFiles({ commit }, { pagination }) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductCountry=${pagination.productForm.countryId}&ProductSize=${pagination.productForm.sizeId}&ProductWeight=${pagination.productForm.weightId}&ProductCategory=${pagination.productForm.categoryId}&ProductHsCode=${pagination.productForm.hsCodeId}&&ProductRange=${pagination.productForm.rangeId}&ProductThread=${pagination.productForm.threadId}&ProductGrade=${pagination.productForm.gradeId}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}`
      );
      await commit("productDealApiReadSuccess", query);
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },
  async allVirtualGetDataWithFiles({ commit }, { pagination }) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get(
        `api/Product?ProductHsCode=${pagination.productForm.hsCodeId}&PartnerId=${pagination.productForm.partenaireId}&ProductType=${pagination.productForm.productType}&Condition=${pagination.productForm.condition}&productWithPo=${pagination.productForm.productWithPo}&offreIsValid=${pagination.productForm.offreIsValid}&publicProduct=${pagination.productForm.publicProduct}&DealNumber=${pagination.productForm.dealNumber}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderByThenBy=deal.number&OrderByDirection=desc`
      );
      await commit("productDealApiReadSuccess", query);
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },
  async allVirtualDeletedGetDataWithFiles({ commit }, { pagination }) {
    try {
      await commit("productDealApiReadBegin");
      const query = await DataService.get(
        `api/Product/GetDeletedItem?ProductHsCode=${pagination.productForm.hsCodeId}&PartnerId=${pagination.productForm.partenaireId}&ProductType=${pagination.productForm.productType}&Condition=${pagination.productForm.condition}&productWithPo=${pagination.productForm.productWithPo}&offreIsValid=${pagination.productForm.offreIsValid}&publicProduct=${pagination.productForm.publicProduct}&DealNumber=${pagination.productForm.dealNumber}&addFiles=true&PageNumber=${pagination.current}&PageSize=${pagination.pageSize}&OrderByThenBy=deal.number&OrderByDirection=desc`
      );
      await commit("productDealApiReadSuccess", query);
    } catch (err) {
      notificationError(err);
      await commit("productDealApiReadErr", err);
    }
  },

  async productDealApiDataSearch({ commit }, searchItem) {
    try {
      await commit("productDealApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("productDealApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("productDealApiReadSuccess", query.data);
        } catch (err) {
          await commit("productDealApiReadErr", err);
        }
      }
    } catch (err) {
      await commit("productDealApiReadErr", err);
    }
  },

  async productDealApiDataDelete({ commit }, { id }) {
    try {
      await commit("productDealApiDeleteBegin");
      await DataService.delete(`/api/product/${id}`);
      await commit("productDealApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("productDealApiDeleteErr", err);

      notificationError(err);
    }
  },
  async productStockApiDataDelete({ commit }, { id }) {
    try {
      await commit("productDealApiDeleteBegin");
      await DataService.delete(`/api/product/${id}`);
      await commit("productStockApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("productDealApiDeleteErr", err);

      notificationError(err);
    }
  },
  async emptyProduct({ commit }) {
    await commit("emptyProductData");
  },
  async productDealApiDataRestore({ commit }, id) {
    try {
      await commit("productDealApiDeleteBegin");
      await DataService.put(`/api/product/RecoverDeletedItem/${id}`, { productId: id });
      await commit("productDealApiRestoreSuccess", id);
      restoreNotificationSuccess();
    } catch (err) {
      await commit("productDealApiDeleteErr", err);

      notificationError(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
